import {Auth} from 'aws-amplify'
import React, { useState} from 'react';
import PropTypes from 'prop-types';


export function GetCurrentUserInfo() {
  const [email, setEmail] = useState();

    Auth.currentUserInfo()
      .then((info) => {
        setEmail(info.attributes["email"]+"");
      })
      .catch(error => console.log(`Error: ${error.message}`));


  return(
    <div>
      User: {email}
    </div>
  )
}

  GetCurrentUserInfo.propTypes = {
    email: PropTypes.string.isRequired
  }