import { Suspense } from 'react';
import { GetCurrentUserInfo } from './currentUserInfo.js';
import { Helmet } from 'react-helmet';

import logo from './SheehanMedicalLLC.png';
import './App.css';
import 'w3-css/w3.css';

import { Amplify, API, Auth } from 'aws-amplify'
import '@aws-amplify/ui-react/styles.css';
import { useAuthenticator, withAuthenticator } from '@aws-amplify/ui-react';
import awsconfig from './aws-exports';

Amplify.configure(awsconfig);
Auth.configure(awsconfig);

// function openInNewTab(href) {
//   Object.assign(document.createElement('a'), {
//     target: '_blank',
//     rel: 'noopener noreferrer',
//     href: href,
//   }).click();
// }


function App() {

  function render() {

    return (
      // Render real UI ...
      <div className="App">
        <Helmet>
          <title>Echo-Sim</title>
        </Helmet>
        <div class="clearfix">
          <div class="box1">
            <img src={logo} class="img" alt="logo" />
          </div>
          <div class="box2">
            <div class="text">
              Echo-Sim<br></br>
              Cloud-based Echocardiograpy Simulator
            </div>
          </div>
        </div>

        <div class="box100">
          <div class="t1">
            <Suspense fallback={<div>Loading Component</div>}>
              {<GetCurrentUserInfo />}
            </Suspense>
          </div>
          <br></br>
          <div class="navbar">
            <button class="button" onClick={callApi}>Launch</button>
            <button class="button" onClick={() => signOut()}>Log Out</button>
          </div>

          <div className="centerBlock" id="urls">
            <div class="t1" id="urlText">
              Press the Launch button to start your session
            </div>
          </div>

          <div className="pad" />

          <div className="centerBlock">
            <div className="downloads20">
              Cloud-based deployment for security and convenience, utilizing Amazon AWS AppStream 2.0
            </div>
            <div className="downloads17">
              <ul>
                <li>No plug-ins or downloads required</li>
                <li>Runs in any HTML5 browser: Chrome, Safari, Edge, Firefox</li>
                <li>In the event that your Windows system experiences difficulty rendering the application, a Windows client application is also available for download and installation</li>
              </ul>
            </div>
          </div>
          <p />
          <div className="centerBlock">
            <div className="downloads20">
              Installers for Simulator Hardware Interfaces (download and install once before launching):
            </div>
            <div className="downloads17b">
              <ul>
                <li><a href="https://smdc-installers.s3.us-west-2.amazonaws.com/INSTALLERS/PatUSBDrvPkgInstall-3.0.3-SWD-25602-13.exe" name="Polhemus Patriot USB Drivers"><span>Polhemus
                  Patriot USB Driver package for Windows</span></a></li>
                <li><a href="https://smdc-installers.s3.us-west-2.amazonaws.com/INSTALLERS/PolhemusPushClient-1.1.0.msi" name="PolhemusPushClient"><span>PolhemusPushClient v1.1.0
                  Installer for Windows</span></a></li>
                <li><a href="https://clients.amazonappstream.com/" name="AppStream Windows Client"><span>Amazon AppStream 2.0 Clients</span></a></li>
              </ul>
            </div>
          </div>

        </div>

      </div>

    );
  }


  async function callApi() {
    document.getElementById("urlText").innerHTML = "Connecting..";
    const user = await Auth.currentAuthenticatedUser()
    console.log("user: ", user)
    const token = user.signInUserSession.idToken.jwtToken
    const requestData = {
      headers: {
        Authorization: token
      }
    }



    const data = await API.get('echosimapi', '/auth', requestData);

    if (data.startsWith("http")) {
      console.log("streaming_url: ", data)
      // openInNewTab(data);
      window.open(data, "_blank");
    } else {  // startsWith("<a")
      document.getElementById("urlText").innerHTML = data;
      // and revert to launch text
      setTimeout(
        function(){
          document.getElementById("urlText").innerHTML = "Session timed out (for security) <br/>Press the Launch button to re-start your session";
        },
        60*1000
     ); 
    }

  }

  const { signOut } = useAuthenticator()

  return render();
}

export default withAuthenticator(App, { hideSignUp: true });
